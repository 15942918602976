import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import {
  Container,
  Grid,
  Icon,
  List,
  Segment,
} from 'semantic-ui-react';
import _ from 'lodash';
import styled from 'styled-components';
import FooterForm from './FooterForm';
import { SubscribeForm } from './styles/SubscribeInput.js';
import footerBackground from '../img/footer-background.svg';
import { useMetaOverride } from '../lib/hoc/useMeta';

const FooterContainer = styled(Container)`
  margin-left: 2.6em !important;
  margin-right: 2.6em !important;
  @media (min-width: 600px) {
    margin-left: 2.6em;
    margin-right: 2.6em;
  }
  padding: 10px 0px;
`;

const FooterList = styled(List)`
  .item {
    font-size: 20px !important;
    font-weight: 500;
    text-decoration: underline;
    color: #424242 !important;
  }
`;

const FooterSubtext = styled.div`
  margin-top: 1.43em;
  @media (min-width: 993px) {
     margin-top: 2.5em;
  }
  line-height: 18px;
  a {
    color: black !important;
    margin-left: 6px;
    margin-right: 6px;
    text-decoration: underline;
  }
`;

const Footer = styled(({ className }) => {
  const data = useStaticQuery(graphql`
    query FooterMeta {
      markdownRemark(fields: { slug: { eq: "/form/footer/" }}) {
        frontmatter {
          description
          social {
            links {
              title
              href
              disable
            }
          }
          links {
            title
            href
            disable
          }
        }
      }
    }
  `);

  const post = useMetaOverride('footer', data.markdownRemark);

  return (
    <Segment vertical
      className={className}
      style={{ paddingTop: '1.5em', backgroundImage: `url(${footerBackground})`, backgroundSize: 'cover' }}>
      <FooterContainer>
        <Grid doubling stackable>
          <Grid.Row
            style={{ paddingBottom: 0 }}>
            <Grid.Column width={8}>
              {/* <FooterMessage className=''>
                Subscribe to our mailing list and receive our Weekly Resource Digest!
              </FooterMessage> */}

              <SubscribeForm>
                <FooterForm />
              </SubscribeForm>
            </Grid.Column>
            <div className="col3" style={{  }}>
              <div>
                <div style={{ marginBottom: 25 }}>
                  <FooterList link horizontal>
                    <List.Item as='a' href={_.get(post, 'frontmatter.links.0.href')} target="_blank" title="Learn more">
                      {_.get(post, 'frontmatter.links.0.title')}
                    </List.Item> | 
                    <List.Item as='a' href={_.get(post, 'frontmatter.links.1.href')} target="_blank"  title="About us">
                      {_.get(post, 'frontmatter.links.1.title')}
                    </List.Item>
                  </FooterList>
                </div>
                <div style={{ marginBottom: 10 }}>
                  Follow us
                </div>
                <div>
                  <List link horizontal>
                    {
                      !_.get(post, 'frontmatter.social.links.0.disable') &&
                      <List.Item as='a' href={_.get(post, 'frontmatter.social.links.0.href')} target="_blank" title={_.get(post, 'frontmatter.social.links.0.title')}>
                        <Icon name="facebook" color='black' size='large' />
                      </List.Item>
                    }
                    {
                      !_.get(post, 'frontmatter.social.links.1.disable') &&
                      <List.Item as='a' href={_.get(post, 'frontmatter.social.links.1.href')} target="_blank" title={_.get(post, 'frontmatter.social.links.1.title')}>
                        <Icon name="instagram" color='black' size='large' />
                      </List.Item>
                    }
                    {
                      !_.get(post, 'frontmatter.social.links.2.disable') &&
                      <List.Item as='a' href={_.get(post, 'frontmatter.social.links.2.href')} target="_blank" title={_.get(post, 'frontmatter.social.links.2.title')}>
                        <Icon name="linkedin" color='black' size='large' />
                      </List.Item>
                    }
                    {
                      !_.get(post, 'frontmatter.social.links.3.disable') &&
                      <List.Item as='a' href={_.get(post, 'frontmatter.social.links.3.href')} target="_blank" title={_.get(post, 'frontmatter.social.links.3.title')}>
                        <Icon name="twitter" color='black' size='large' />
                      </List.Item>
                    }
                  </List>
                </div>
              </div>
              <FooterSubtext>
                © 2020 BestFit |
                All Rights Reserved |
                <a href="/privacy-policy" target="_blank" title="Privacy Policy">
                  Privacy Policy
                </a>|
                <a href="/terms-conditions" target="_blank" title="Terms & Conditions">
                  Terms & Conditions
                </a>
              </FooterSubtext>
            </div>
          </Grid.Row>
        </Grid>
      </FooterContainer>
    </Segment>
  );
})`
  .row {
    justify-content: space-between !important;
    .col3 {
      display: flex !important;
      flex-direction: column;
      justify-content: flex-start;
      width: 360px !important;
    }
  }
`;

export default Footer;
