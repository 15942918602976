import React from 'react';
import { Menu, Responsive, Icon, Image, Button } from 'semantic-ui-react';
import _ from 'lodash';
import { useStaticQuery, graphql } from "gatsby";

import styled from 'styled-components';
import { useMetaOverride } from '../lib/hoc/useMeta';
import HamburgerNav from './HambergerNav';

const VertMenu = styled(Menu)`
  display: ${props => props.verticalMenu ? 'none' : 'initial'} !important;
  border: none !important;

  .item {
    background: #CD5B49 !important;
    color: white !important;
    text-align: center;
    font-size: 1.3em;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }

  .active {
    text-decoration: underline !important;
  }
  .item.btn  {
    padding-top: .5em !important;
    padding-bottom: .5em !important;
    &:hover {
      text-decoration: none !important;
    }
    .button {
      background: #CD5B49 !important;
    }
  }
`;


const metaQuery = graphql`
  query MenuMetaMobileQuery {
    markdownRemark(fields: { slug: { eq: "/meta/"}}) {
      frontmatter {
        logo {
          title
          href
          disable
          src {
            publicURL
          }
        }
        whiteLogo {
          title
          href
          disable
          src {
            publicURL
          }
        }
        menus {
          links {
            title
            href
            disable
          }
          buttons {
            title
            disable
            href
          }
        }
      }
    }
  }
`;

const VerticalMenu = ({ handleClose, displayVertMenu }) => {
  const [ activeItem, $activeItem ] = React.useState('home');
  const handleItemClick = (e, { name }) => $activeItem(name);
  // getActiveLinkProps = ({ isPartiallyCurrent, ...rest }) => {
  //   return isPartiallyCurrent
  //   ? { className: 'item active' }
  //   : { className: 'item' }
  // };
  const data = useStaticQuery(metaQuery);
  const post = useMetaOverride('meta', data.markdownRemark);

  return (
    <VertMenu vertical fluid borderless centered verticalMenu={displayVertMenu}>
      <Menu.Item fitted='vertically' onClick={handleClose} position='right' style={{textAlign: 'right'}}>
        <Icon name='close' fitted/>
      </Menu.Item>

      <Menu.Item
        as='a'
        name='home'
        onClick={handleItemClick}
        href={_.get(post, 'frontmatter.logo.href')}
        to={_.get(post, 'frontmatter.logo.href')}
      >
        <Image centered src={_.get(post, 'frontmatter.whiteLogo.src.0.publicURL')} width='55px' alt="white logo"/>
      </Menu.Item>

      <Menu.Item
        as='a'
        name='about'
        onClick={handleItemClick}
        href={_.get(post, 'frontmatter.menus.links.0.href')}
        to={_.get(post, 'frontmatter.menus.links.0.href')}
      >
        {_.get(post, 'frontmatter.menus.links.0.title')}
      </Menu.Item>
      {/* <IconRow style={{ display: 'none' }}/> */}
      <Menu.Item
        as='a'
        name='login'
        onClick={handleItemClick}
        active={activeItem === 'login'}
        href='/login'
        style={{display: 'none'}}
      >
        Log in
      </Menu.Item>

      {
        !_.get(post, 'frontmatter.menus.buttons.0.disable') &&
        <Menu.Item
          as='a'
          name='submitres'
          {..._.omitBy({ href: _.get(post, 'frontmatter.menus.buttons.0.href') }, _.isNil)}
          target="_blank"
          className="btn"
        >
          <MobileSignUpBtn compact inverted >{_.get(post, 'frontmatter.menus.buttons.0.title')}</MobileSignUpBtn>
        </Menu.Item>
      }
      {
        !_.get(post, 'frontmatter.menus.buttons.1.disable') &&
        <Menu.Item
          as='a'
          name='signup'
          onClick={handleItemClick}
          active={activeItem === 'signup'}
          href='/signup'
          className="btn"
        >
          <MobileSignUpBtn compact inverted >{_.get(post, 'frontmatter.menus.buttons.1.title')}</MobileSignUpBtn>
        </Menu.Item>
      }
    </VertMenu>
  );
};

const MobileSignUpBtn = styled(Button)`
  font-size: 18px !important;
  width: 60%;
  color: white !important;
  font-weight: 500 !important;
`;

const MobileNavigation = () => {
  const [hideMenu, $hideMenu] = React.useState(true);
  const handleMenu = () => $hideMenu(!hideMenu)
  return (
    <Responsive maxWidth={767}>
      {hideMenu 
        ? <HamburgerNav handleMenu={handleMenu} /> 
        : <VerticalMenu displayVertMenu={hideMenu} handleClose={handleMenu} />
      }
    </Responsive>
  );
};

export default MobileNavigation;