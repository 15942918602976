import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import _ from 'lodash';
import PopupSubscribeForm from './PopupSubscribeForm';

import CatList1 from './CatList/CatList1';
import Banner from './CatList/Banner';
import LocationList from './CatList/LocationList';
import { getOneOf } from '../lib/utils';
import NewsCard from './CatList/NewsCard';

const StyledContainer = styled(Container)`
  min-height: 40vh;
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
  }
`;

export const HomePageTemplate = ({ data, post }) => {
  const locations = _.get(post, 'frontmatter.locations', []);
  return (
    <div>
      <PopupSubscribeForm />
      <Helmet>
        <title>BestFit</title>
        <link rel="canonical" href="https://best-fit.app/" />
        <link rel="preconnect" href="https://js.hsforms.net" />
        <link rel="preconnect" href="https://forms.hsforms.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="http://js.hsforms.net" />
      </Helmet>
      <section>
        <StyledContainer>
          <Banner item={_.get(post, 'frontmatter')} />
          <NewsCard item={_.get(post, 'frontmatter')} />
          {
            _.get(locations, 'length') >= 2 ?
            <LocationList locations={locations} /> :
            <CatList1 cats={getOneOf(post, 'frontmatter.categories').map(cat => {
              return {
                ...cat,
                slug: `${_.trimEnd(_.get(locations, '0.slug', ''), '/')}/${_.trimStart(cat.slug, '/')}`
              };
            })} />
          }

        </StyledContainer>
      </section>
    </div>
  )
}


export default HomePageTemplate;
