/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import CatCard1 from './CatCard1';

export const CatList1 = props => {
  const { cats } = props;
  return (
    <div className={cx(props.className)}>
      <div className="title text-h3 txt-color-3">Categories</div>
      <Grid >
        {
          cats.map((cat, index) => {
            const catType = _.get(['type-1', 'type-2', 'type-3', 'type-4', 'type-5'], index % 4);
            return (
              <Grid.Column key={cat.name} mobile={16} tablet={8} computer={4} className="col grid">
                <CatCard1 cat={cat} className={catType}/>
              </Grid.Column>
            )
          })
        }
      </Grid>

    </div>
  )
};

export default styled(CatList1)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  > .title {
    font-weight: 700;
    padding-left: 10px;
    margin-top: 1.2rem;
    margin-bottom: 0rem;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      /* display: none; */
    }  
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }

`;
