/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Card, Image } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import { getOneOf } from '../../lib/utils';

export const CatCard1 = props => {
  const { cat } = props;
  return (
    <Card className={cx(props.className)}>
      <Card.Content className="blog-content">
        <div className="cover">
          <Image
            className="thumbnail resCategoryLink"
            src={getOneOf(cat, ['thumbnail.publicURL', 'thumbnail'])}
            as="a"
            href={_.get(cat, 'slug')}
          />
        </div>
        <div className="footer">
          <div className="line-title text-h4">
            {_.get(cat, 'name', 'Category')}
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

export default styled(CatCard1)`
  .info {
    font-size: ${props => props.theme.sizeSmallText};
    line-height: 25px;
  }
  .cover {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding-top: 60%;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
      padding-top: 80%;
    }
    .thumbnail {
      position: absolute;
      top: 0;
      bottom: 50px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        bottom: 36px;
      }
      width: 100%;
      max-width: 100%;
      text-align: center;
      > img {
        max-height: 100% !important;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
    .coverFooter {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
  }
  .footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 7px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding: 2px;
    }
    .line-title {
      font-weight: 600;
      text-align: center;
      margin-bottom: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @supports (-webkit-line-clamp: 1) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  height: auto;
  overflow: hidden;
  margin: 20px auto !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin: 0 !important;
    width: 100% !important;
  }

  & .content {
    padding: 0px !important;
    position: relative;
    border: none !important;
  }
  &.type-1 {
    .content {
      background: ${props => props.theme.bg1} !important;
      img {
        padding: 4px 20px 0;
        width: auto;
      }
    }
    .line-title {
      color: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    .content {
      background: ${props => props.theme.bg1} !important;
      img {
        padding: 2px 0 0;
      }
    }
    .line-title {
      color: ${props => props.theme.txtTeal};
    }
  }
  &.type-3 {
    .content {
      background: ${props => props.theme.bg1} !important;
      img {
        padding: 4px 0 0;
        width: auto;
      }
    }
    .line-title {
      color: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    .content {
      background: ${props => props.theme.bg1} !important;
      img {
        width: auto;
      }
    }
    .line-title {
      color: ${props => props.theme.txtPink};
    }
  }
`;
