import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import HomeLayout from '../components/HomeLayout';

import { HTMLContent } from '../components/Content'

import HomePageTemplate from '../components/HomePageTemplate'
import { useMetaOverride } from '../lib/hoc/useMeta';

const HomePage = ({ data, pageContext }) => {
  const { markdownRemark: defaultPost } = data;
  const post = useMetaOverride('home', defaultPost);

  return (
    <HomeLayout headerText={_.get(post, 'frontmatter.description')}>
      <HomePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        post={post}
        pageContext={pageContext}
        data={data}
      />
    </HomeLayout>
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    metaMarkDown: markdownRemark(fields: { slug: { eq: "/meta/"}}) {
      html
      frontmatter {
        title
        description
        areas {
          title
          description
          slug
          actionText
          actionLink
        }
        categories {
          name
          title
          description
          className
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          slug
          airtable
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        actionText
        actionLink
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        thumbnailUrl
        thumbnailAlt
        thumbnailTarget
        thumbnailVisibility
        newsVisibility
        locations {
          name
          slug
          title
          detail
        }
        categories {
          name
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          slug
          airtable
        }
      }
    }
  }
`

export default HomePage;
