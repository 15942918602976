import React from 'react';
import { Container, Menu, Icon, Image } from 'semantic-ui-react';
import _ from 'lodash';
import { useStaticQuery, graphql } from "gatsby";

import styled from 'styled-components';
import { useMetaOverride } from '../lib/hoc/useMeta';


const metaQuery = graphql`
  query HambergerNavMetaMobileQuery {
    markdownRemark(fields: { slug: { eq: "/meta/"}}) {
      frontmatter {
        logo {
          title
          href
          disable
          src {
            publicURL
          }
        }
      }
    }
  }
`;

const HamburgerNavPure = props => {
  const data = useStaticQuery(metaQuery);
  const post = useMetaOverride('meta', data.markdownRemark);

  return (
    <Container style={{backgroundColor: 'transparent'}} className={props.className} >
      <Menu borderless secondary>
        <Menu.Item fitted='horizontally' >
          <Image rounded size='mini' src={_.get(post, 'frontmatter.logo.src.0.publicURL')} alt="logo" />
        </Menu.Item>
        <Menu.Item onClick={props.handleMenu} position='right'>
          <Icon name='sidebar' className="icon-menu" size='big' style={{ color: '#CD5B49' }} />
        </Menu.Item>
      </Menu>
    </Container>
  );
}

const HamburgerNav = styled(HamburgerNavPure)`
  .icon-menu: {
    color: #CD5B49 !important;
  }
`;

export default HamburgerNav;
