import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const normPaths = (paths) => {
  return _.cloneDeep(paths).map((path, index) => {
    let href = _.get(path, 'href');
    const slug = _.get(path, 'slug');
    if(!href && slug) {
      const prevHref = _.get(paths, [index - 1, 'href']);
      if(prevHref) {
        href = `${_.trimEnd(prevHref, '/')}/${_.trimStart(slug, '/')}`;
        _.set(path, 'href', href)
      }
    }
    return path;
  })
}

const Breadscrumbs = styled(({ className, paths }) => {
  return (
    <div className={className}>
      {
        normPaths(_.castArray(paths)).map(path => {
          const label = _.get(path, 'label');
          let href = _.get(path, 'href');
          if(label && href) {
            return <a key={label} className="item" href={href}>{label}</a>
          }
          if(label) {
            return <span key={label} className="item">{label}</span>
          }
          return null;
        })
      }
    </div>
  )
})`
  display: inline-block;
  .item {
    color: inherit !important;
    text-transform: uppercase;
    text-decoration: underline;
    &:not(:first-child) {
      &:before {
        content: '>';
        margin: 0px 6px;
        text-decoration: none;
        display: inline-block;
      }
    }
    &:last-child {
      text-decoration: none;
    }
  }
`
export default Breadscrumbs;